import React from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { useIntl } from "gatsby-plugin-intl"

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import ContactForm from "../components/sections/contact-form"


const Griffier = () => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "page.title.griffier" })} description={intl.formatMessage({ id: "page.description.griffier" })} />
        <section>
          
          <div className="terms-wrapper">
            <h2 className="header">{intl.formatMessage({ id: "product.griffier.header" })}</h2>
            <div className="wrapper">
              <div className="presentie-wrapper">
                <div className="application-banner application-banner-griffier"></div>
                <h3 className="header">{intl.formatMessage({ id: "product.griffier.body.title1" })}</h3>
                <p>{intl.formatMessage({ id: "product.griffier.body.descr1" })}</p>

                <div className="center top-space">
                  <AnchorLink href="#contact-form" className="link link-active">{intl.formatMessage({ id: "product.griffier.request" })}</AnchorLink>
                </div>

                <h4 className="header">{intl.formatMessage({ id: "product.griffier.body.title2" })}</h4>
                <p>{intl.formatMessage({ id: "product.griffier.body.descr2.para1" })}</p>
                <p>{intl.formatMessage({ id: "product.griffier.body.descr2.para2" })}</p>

                <h4 className="header">{intl.formatMessage({ id: "product.griffier.body.title3" })}</h4>
                <p>{intl.formatMessage({ id: "product.griffier.body.descr3" })}</p>

                <h4 className="header">{intl.formatMessage({ id: "product.griffier.body.title4" })}</h4>
                <p>{intl.formatMessage({ id: "product.griffier.body.descr4" })}</p>

                <h4 className="header">{intl.formatMessage({ id: "product.griffier.body.title5" })}</h4>
                <p>{intl.formatMessage({ id: "product.griffier.body.descr5.para1" })}</p>
                <p>{intl.formatMessage({ id: "product.griffier.body.descr5.para2" })}</p>

                <h4 className="header">{intl.formatMessage({ id: "product.griffier.body.title6" })}</h4>
                <ul>
                  <li>{intl.formatMessage({ id: "product.griffier.body.list6.data1" })}</li>
                  <li>{intl.formatMessage({ id: "product.griffier.body.list6.data2" })}</li>
                  <li>{intl.formatMessage({ id: "product.griffier.body.list6.data3" })}</li>
                  <li>{intl.formatMessage({ id: "product.griffier.body.list6.data4" })}</li>
                  <li>{intl.formatMessage({ id: "product.griffier.body.list6.data5" })}</li>
                  <li>{intl.formatMessage({ id: "product.griffier.body.list6.data6" })}</li>
                  <li>{intl.formatMessage({ id: "product.griffier.body.list6.data7" })}</li>
                  <li>{intl.formatMessage({ id: "product.griffier.body.list6.data8" })}</li>
                </ul>

                <div className="center top-space">
                  <h3 className="header">{intl.formatMessage({ id: "product.griffier.body.title7" })}</h3>
                  <p>{intl.formatMessage({ id: "product.griffier.body.descr7" })}</p>
                </div>


              </div>

            </div>

          </div>

        </section>

        <section id="contact-form">
          <ContactForm />
        </section>
    </Layout>
  )
};

export default Griffier;